<template>
  <div>
    <EditDialog width="80%" :isReturn="true" @closeFn="$emit('offsetCancel')" :isShow.sync="isShow">
      <div slot="title" class="title">冲红发票</div>
      <div slot="content" class="content">
        <div class="operation_search">
          <div class="search_center">
            单据编号：
            <el-input
              v-model="parameter.expenseNumber"
              class="ipt_width expense__width"
              placeholder="请输入单据单编号"
              clearable
            >
            </el-input>
          </div>
          <div class="search_center">
            项目名称：
            <el-select
              v-model="parameter.projectId"
              clearable
              filterable
              placeholder="请选择项目名称"
              class="ipt_width project_width"
            >
              <el-option
                v-for="item in projectList"
                :key="item.id"
                :label="item.projectName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search_center">
            合同名称：
            <el-select
              v-model="parameter.agreementId"
              clearable
              filterable
              placeholder="请选择合同名称"
              class="ipt_width project_width"
            >
              <el-option
                v-for="item in agreementList"
                :key="item.id"
                :label="item.agreementName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>

          <div class="search_center">
            申请日期：
            <el-date-picker
              class="date_width"
              v-model="createdDate"
              :pickerOptions="pickerOptions"
              type="daterange"
              range-separator="至"
              start-placeholder="申请日期（始）"
              end-placeholder="申请日期（末）"
            >
            </el-date-picker>
          </div>

          <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
            >搜索</el-button
          >
          <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
        </div>
        <el-table
          v-loading="loading"
          ref="multipleTable"
          tooltip-effect="dark"
          height="500"
          border
          :data="tableData"
        >
          <el-table-column
            type="index"
            fixed="left"
            label="序号"
            align="center"
            width="50"
          ></el-table-column>
          <el-table-column
            prop="expenseNumber"
            fixed="left"
            label="单据编号"
            align="center"
            min-width="160"
          ></el-table-column>
          <el-table-column
            prop="projectName"
            fixed="left"
            min-width="320"
            sortable
            label="项目名称"
            :show-overflow-tooltip="false"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="agreementName"
            fixed="left"
            min-width="320"
            sortable
            label="合同名称"
            :show-overflow-tooltip="false"
            align="center"
          ></el-table-column>
          <el-table-column prop="sumCost" min-width="110" sortable label="开票金额" align="center">
            <template slot-scope="scope">
              {{ scope.row.sumCost | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column
            prop="companyType"
            :show-overflow-tooltip="false"
            min-width="120"
            sortable
            label="销售方"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.companyType | dict(companyType) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="customersUnit"
            :show-overflow-tooltip="false"
            min-width="120"
            sortable
            label="购买方"
            align="center"
          ></el-table-column>

          <el-table-column prop="taskDate" min-width="110" sortable label="申请时间" align="center">
            <template slot-scope="scope">
              {{ scope.row.taskDate | dateFormat }}
            </template>
          </el-table-column>

          <el-table-column label="操作" fixed="right" align="center" width="90">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="small"
                :disabled="offsetId === scope.row.id"
                @click="onSelectFn(scope.row)"
              >
                {{ offsetId === scope.row.id ? '已选' : '选择' }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          prev-text="上一页"
          next-text="下一页"
          layout="->,total, prev, pager, next, slot, jumper"
          :current-page="parameter.pageNow"
          :page-size="parameter.pageSize"
          :total="parameter.total"
          @size-change="handleSizeChange"
          @current-change="pageChangeHandler"
        >
          <span class="el-pagination__jump e_a_pagination">
            <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
            <span style="padding-top: 1px">条/页</span>
          </span>
        </el-pagination>
        <div style="color: red; display: flex; justify-content: center">
          选择冲红发票后，可以选择覆盖已填单据，也可以不覆盖。
        </div>
      </div>
      <div slot="footer" class="footer">
        <el-button type="info" plain @click="$emit('offsetCancel')">返回</el-button>
      </div>
    </EditDialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'OffsetDialog',
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    offsetId: {
      type: String,
      default: '',
    },
    form: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      loading: false, // 表格 加载
      // 查询条件
      parameter: {
        projectId: null,
        agreementId: null,
        expenseNumber: null,
        applyType: 'APPROVAL_PASS',
        pageNow: 1,
        pageSize: 50,
        total: 0,
        offsetStatus: 0, //发票状态
      },
      pageSize: 0,
      tableData: [], // 表格数据
      companyType: [],
      projectList: [], // 项目数据
      agreementList: [], // 合同数据
      createdDate: [], //搜索框绑定日期
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getProjectList() /** 加载 "项目/合同" 数据invoice **/
    this.getTableData() /** 加载 "发票申请" 数据 **/
  },
  watch: {
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
  },
  methods: {
    //选择冲红发票
    onSelectFn(row) {
      this.$confirm('确认冲红发票可以覆盖已填单据内容，是否需要覆盖？', '提示', {
        confirmButtonText: '需要',
        cancelButtonText: '不需要',
        type: 'warning',
      })
        .then(async () => {
          const { data } = await this.$api.invoice.selectDetailById(row.id)
          if (data) {
            this.$emit('onCoverInvoice', data)
          }
        })
        .catch(() => {
          this.$emit('onCancelInvoice', row)
        })
    },
    /** 加载 "发票申请" 数据 **/
    getTableData() {
      this.loading = true

      if (this.createdDate && this.createdDate != []) {
        this.parameter.startDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      } else if (this.createdDate == null) {
        this.parameter.startDate = null
        this.parameter.endDate = null
      }
      this.$api.invoice
        .getApplyForList(this.parameter)
        .then(res => {
          this.tableData = res.data?.records
          this.parameter.total = res.data?.total
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    /** 加载 "项目/合同" 数据 **/
    getProjectList() {
      this.$api.project
        .projectList({
          pageSize: 10000,
          pageNow: 1,
        })
        .then(res => {
          this.projectList = res.data.records
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.agreement
        .listByProjectByStaffToNam({
          pageSize: 10000,
          pageNow: 1,
        })
        .then(res => {
          this.agreementList = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('COMPANY_TYPE_FULL', true)
        .then(res => {
          this.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.projectId = null
      this.parameter.agreementId = null
      this.parameter.expenseNumber = null
      this.parameter.startDate = null
      this.parameter.endDate = null
      this.createdDate = []
      this.parameter.pageNow = 1
      this.getTableData() /** 加载 "发票发放" 数据 **/
    },
    /**
     * 点击 "表格 -- 分页" 操作
     * @param page      当前页
     */
    pageChangeHandler(page) {
      this.parameter.pageNow = page
      this.getTableData() /** 加载 "发票申请" 数据 **/
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getTableData()
    },
    handlePageSize() {
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getTableData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
.content {
  height: calc(90vh - 164px);
  overflow: auto;
}
.operation_search {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  .date_width {
    width: 380px;
    margin-right: 15px;
  }
  .search_center {
    display: inline-block;
    line-height: 40px;
    height: 45px;
    white-space: nowrap;
  }
  .ipt_width {
    width: 165px;
    margin-right: 15px;
    margin-bottom: 5px;
  }
  .project_width {
    width: 400px;
  }
}
</style>
